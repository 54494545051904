
import * as directusRuntime$02OzPHLq7I from '/mcc/node_modules/.pnpm/@nuxt+image@1.8.0/node_modules/@nuxt/image/dist/runtime/providers/directus'
import * as ipxRuntime$hGily7KWtV from '/mcc/node_modules/.pnpm/@nuxt+image@1.8.0/node_modules/@nuxt/image/dist/runtime/providers/ipx'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "ipx",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['directus']: { provider: directusRuntime$02OzPHLq7I, defaults: {"baseURL":"https://directus.maidstonecanoeclub.net/assets/"} },
  ['ipx']: { provider: ipxRuntime$hGily7KWtV, defaults: {} }
}
        